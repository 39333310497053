/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https:www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https:www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react
import React from "react";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

// EmptyLayout components
import EmptyLayout from "layouts/authentication/components/EmptyLayout";

// Firebase authentication
import { getAuth, sendEmailVerification } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

function Cover() {
  const auth = getAuth();

  const [isError, SetIsError] = React.useState(false);
  const [errorMessage, SetErrorMessage] = React.useState("");
  const [isSuccess, SetIsSuccess] = React.useState(false);
  const [user, loading] = useAuthState(auth);

  const sendEmailVerificationHandler = () => {
    sendEmailVerification(user)
      .then(() => {
        SetIsSuccess(true);
      })
      .catch((exError) => {
        const errorCode = exError.code;
        const errortTitle = "Send Email Verification: ";
        SetErrorMessage(errortTitle + errorCode);
        SetIsError(true);
      });
  };

  return (
    <EmptyLayout>
      {loading && (
        <div className="loading">
          <div className="loader" />
        </div>
      )}

      {isError && <MDAlert color="error">Error: {errorMessage}</MDAlert>}
      {isSuccess && (
        <MDAlert color="success">
          <MDTypography variant="body2" color="white">
            We&apos;ll send an email to {user.email} in 5 minutes. Open it up to activate your
            account. (if you can&apos;t find it in your inbox, Please check your SPAM or junk mail
            folder)
          </MDTypography>
        </MDAlert>
      )}
      {!isSuccess && !isError && (
        <>
          <MDTypography variant="h5" gutterBottom>
            Email {user.email} not verified
          </MDTypography>
          <MDTypography variant="body1" gutterBottom>
            We have sent you an email to verify your account.
          </MDTypography>
          <MDTypography variant="body1" gutterBottom>
            Please check your inbox and click the link in the email to verify your account.
          </MDTypography>
          <MDTypography variant="body1" gutterBottom>
            If you don&apos;t see the email, please check your spam folder.
          </MDTypography>
          <MDButton color="primary" variant="contained" onClick={sendEmailVerificationHandler}>
            Resend
          </MDButton>
        </>
      )}
    </EmptyLayout>
  );
}

export default Cover;
