/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Firebase
import { getAuth, getIdToken } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

// Axios
import Axios from "axios";
import MDAlert from "components/MDAlert";

function DataTables() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [data, setData] = useState();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const auth = getAuth();
  const [user] = useAuthState(auth);

  const fetchData = () => {
    getIdToken(user).then((idToken) => {
      Axios.get(
        `${API_URL}Abtixco/accounts/`,
        {
          headers: {
            Authorization: `Bearer  ${idToken}`,
          },
        }
      )
        .then((response) => {          
          const accounts = response.data.accounts;
          const dataTableData = {
            columns: [
              { Header: "Name", accessor: "name", width: "20%" },
              { Header: "Account Number", accessor: "accountNumber", width: "20%" },
              { Header: "Legal Business Name", accessor: "legalBusinessName", width: "20%" },
              { Header: "Status", accessor: "status", width: "20%" },
              { Header: "Kind", accessor: "kind", width: "20%" },
            ],
            rows: accounts
          };          
          setData(dataTableData);
          setTotal(response.data.accounts.length);
          setIsLoading(false);
        }).catch(error => {
          console.log(error);
        });
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {(total > 0 && !isLoading) &&
        <MDBox pt={6} pb={3}>
          <MDBox mb={3}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  Accounts
                </MDTypography>
              </MDBox>
              {data &&
                <DataTable table={data} canSearch />
              }
            </Card>
          </MDBox>
        </MDBox>
      }

      {(total === 0 && !isLoading) &&
        <MDAlert color="warning">
          <MDTypography variant="body2" color="white">
            No records found.
          </MDTypography>
        </MDAlert>
      }

      <Footer />
    </DashboardLayout>
  );
}

export default DataTables;
