/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https:www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https:www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react
import React from "react";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";

// EmptyLayout components
import EmptyLayout from "layouts/authentication/components/EmptyLayout";

// Firebase authentication
import { getAuth, signOut } from "firebase/auth";

function Cover() {
  const auth = getAuth();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, SetIsError] = React.useState(false);
  const [errorMessage, SetErrorMessage] = React.useState("");
  const [isSuccess, SetIsSuccess] = React.useState(false);

  signOut(auth)
    .then(() => {
      SetIsSuccess(true);
      setIsLoading(false);
    })
    .catch((error) => {
      SetErrorMessage(error.message);
      SetIsError(true);
      setIsLoading(false);
    });

  return (
    <EmptyLayout>
      {isLoading && (
        <div className="loading">
          <div className="loader" />
        </div>
      )}

      {isError && <MDAlert color="error">Error: {errorMessage}</MDAlert>}
      {isSuccess && (
        <MDAlert color="success">
          <MDTypography variant="body2" color="white">
            You&nbsp;have&nbsp;been&nbsp;successfully&nbsp;logged&nbsp;out.
            <MDTypography
              component="a"
              href="/login"
              variant="body2"
              fontWeight="medium"
              color="white"
            >
              &nbsp;Log&nbsp;me&nbsp;in
            </MDTypography>
          </MDTypography>
        </MDAlert>
      )}
    </EmptyLayout>
  );
}

export default Cover;
