/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Material Dashboard 2 React Examples
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

// Images
import booking1 from "assets/images/products/product-1-min.jpg";

function Analytics() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox py={3}>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mt={3}>
                <SimpleBlogCard
                  image={booking1}
                  title="Citadel Engineering Company"
                  description="Citadel Engineering Services was originally incorporated in the USA in 2015 as Engineering, Calibration & Inspection Body. After initial involvement in several development projects inside the USA and Europe, CEC started business in the early days of oil exploration in the Kurdistan Region, CEC was contracted as one of the 1st Calibration and Inspection Bodies in Iraq and the Kurdistan Region."
                  action={{
                    type: "external",
                    route: "https://citadel-engineering.com/about-us/",
                    color: "info",
                    label: "READ MORE",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox> */}
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
