/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react
import React, { useState } from "react";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import bgImage from "assets/images/illustrations/bg1.jpg";

// Authentication
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// @mui material lab components
import LoadingButton from "@mui/lab/LoadingButton";

function Illustration() {
  const [email, setEmail] = useState("");
  const [emailHelperText, setEmailHelperText] = useState("");
  const [emailFieldIsValid, setEmailFieldIsValid] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [passwordFieldIsValid, setPasswordFieldIsValid] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = (emailStr, passwordStr) => {
    let result = true;

    if (emailStr === "") {
      setEmailHelperText("Email is required");
      setEmailFieldIsValid(false);
      result = false;
    } else if (!emailStr.includes("@")) {
      setEmailHelperText("Email is invalid");
      setEmailFieldIsValid(false);
      result = false;
    } else {
      setEmailHelperText("");
      setEmailFieldIsValid(true);
    }

    if (passwordStr === "") {
      setPasswordHelperText("Password is required");
      setPasswordFieldIsValid(false);
      result = false;
    } else {
      setPasswordHelperText("");
      setPasswordFieldIsValid(true);
    }
    return result;
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
    validateForm(event.target.value, password);
  };

  const onPasswordChange = (event) => {
    validateForm(email, event.target.value);
    setPassword(event.target.value);
  };

  const loginHandler = () => {
    const isValidate = validateForm(email, password);

    if (!isValidate) return;

    setLoading(true);

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = { user: userCredential.user };
        console.log(user.emailVerified);
        navigate("/");
      })
      .catch((exError) => {
        const errorCode = exError.code;
        const errorMessage = exError.message;
        setError(errorCode);
        console.log(errorMessage);
        setLoading(false);
      });
  };

  const alertContent = (name) => (
    <MDTypography variant="body2" color="white">
      {name}
    </MDTypography>
  );

  const signInTitle = " | Sign In";

  return (
    <IllustrationLayout
      title={process.env.REACT_APP_WEBSITE_TITLE + signInTitle}
      description="Enter your email and password to sign in"
      illustration={bgImage}
    >
      <MDBox component="form" role="form">
        {error && <MDAlert color="warning">{alertContent(error)}</MDAlert>}
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Email"
            required
            error={!emailFieldIsValid}
            variant="standard"
            fullWidth
            helperText={emailHelperText}
            onChange={onEmailChange}
            value={email}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            variant="standard"
            error={!passwordFieldIsValid}
            required
            fullWidth
            onChange={onPasswordChange}
            value={password}
            helperText={passwordHelperText}
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          {loading ? (
            <LoadingButton loading variant="outlined" size="large" fullWidth />
          ) : (
            <MDButton variant="gradient" color="info" size="large" fullWidth onClick={loginHandler}>
              sign in
            </MDButton>
          )}
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            Don&apos;t have an account?{" "}
            <MDTypography
              component={Link}
              to="/sign-up/"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mt={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Forgot your password?{" "}
            <MDTypography
              component={Link}
              to="/forgot/"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Reset it.
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Illustration;
