/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react
import React from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import EmptyLayout from "layouts/authentication/components/EmptyLayout";

// firebase functions
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function Cover() {
  const auth = getAuth();
  const [email, setEmail] = React.useState("");
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [emailFieldIsValid, setEmailFieldIsValid] = React.useState(true);
  const [emailHelperText, setEmailHelperText] = React.useState("");
  const [error, setError] = React.useState(null);

  const validateForm = (emailStr) => {
    let result = true;

    if (emailStr === "") {
      setEmailHelperText("Email is required");
      setEmailFieldIsValid(false);
      result = false;
    } else if (!emailStr.includes("@")) {
      setEmailHelperText("Email is invalid");
      setEmailFieldIsValid(false);
      result = false;
    } else {
      setEmailHelperText("");
      setEmailFieldIsValid(true);
    }

    return result;
  };

  const resetHandler = () => {
    const isValidate = validateForm(email);

    if (!isValidate) return;

    sendPasswordResetEmail(auth, "email")
      .then(() => {
        setIsSuccess(true);
      })
      .catch((exError) => {
        const errorCode = exError.code;
        setError(errorCode);
      });
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
    validateForm(event.target.value);
  };

  const alertContent = (name) => (
    <MDTypography variant="body2" color="white">
      {name}
    </MDTypography>
  );

  return (
    <EmptyLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {isSuccess ? (
            <MDTypography variant="h5" color="success" align="center">
              An e-mail has been sent to your e-mail address.
            </MDTypography>
          ) : (
            <MDBox component="form" role="form">
              {error && <MDAlert color="warning">{alertContent(error)}</MDAlert>}
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  required
                  error={!emailFieldIsValid}
                  variant="standard"
                  fullWidth
                  helperText={emailHelperText}
                  onChange={onEmailChange}
                  value={email}
                />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth onClick={resetHandler}>
                  reset
                </MDButton>
              </MDBox>
              <MDBox mt={3} textAlign="center">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/sign-up/"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </EmptyLayout>
  );
}

export default Cover;
